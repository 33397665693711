<template>
  <div class="flex min-h-screen">
    <BusinessInfoSideBar />
    <BusinessAddressForm />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import BusinessAddressForm from '@/components/BusinessAddressForm.vue'
export default defineComponent({
  name: 'BusinessAddressPage',
  components: {
    BusinessInfoSideBar,
    BusinessAddressForm
  }
})
</script>

<style scoped></style>