
import { defineComponent } from 'vue'
import BusinessInfoSideBar from '@/components/BusinessInfoSideBar.vue'
import BusinessAddressForm from '@/components/BusinessAddressForm.vue'
export default defineComponent({
  name: 'BusinessAddressPage',
  components: {
    BusinessInfoSideBar,
    BusinessAddressForm
  }
})
