
import { defineComponent, ref, watch } from 'vue'
import ErrorMessage from '@/components/UI/ErrorMessage.vue'
import { Icon, addIcon } from '@iconify/vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import countries from '@/util/countries.ts'
import states from '@/util/states.ts'
import Tooltip from './Tooltip.vue'

addIcon('arrow-right', arrowRight)

export default defineComponent({
  components: {
    Icon,
    ErrorMessage,
    Tooltip
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const addressLine = ref(store.state.business.addressLine)
    const address = ref(store.state.business.address)
    const country = ref(store.state.business.country)
    const state = ref(store.state.business.region)
    const city = ref(store.state.business.city)
    const zipcode = ref(store.state.business.zipcode)
    const isReselling = ref(store.state.business.isReselling)

    const formErrors = ref({
      addressError: false,
      countryError: false,
      stateError: false,
      cityError: false,
      zipcodeError: false,
      isResellingError: false
    })

    watch(state, () => {
      formErrors.value.stateError = false
    })

    const verifyErrors = () => {
      if (!country.value) formErrors.value.countryError = true
      if (!state.value || !state.value?.trim())
        formErrors.value.stateError = true
      if (!address.value || !address.value?.trim()) {
        address.value = address.value?.trim()
        formErrors.value.addressError = true
      }
      if (!city.value || !city.value?.trim()) {
        city.value = city.value?.trim()
        formErrors.value.cityError = true
      }
      if (!zipcode.value || !zipcode.value?.trim()) {
        zipcode.value = zipcode.value?.trim()
        formErrors.value.zipcodeError = true
      }
      if (typeof isReselling.value === 'undefined') {
        formErrors.value.isResellingError = true
      }
    }

    const updateCountry = (value: string) => {
      formErrors.value.countryError = false
      country.value = value
      state.value = ''
    }

    const updateState = (value: string) => {
      formErrors.value.stateError = false
      state.value = value
    }

    const prevStep = () => {
      router.push({
        name: 'businessInfoPage'
      })
    }

    const updateIsReseeling = () => {
      formErrors.value.isResellingError = false
    }

    const nextStep = () => {
      verifyErrors()
      for (const error in formErrors.value) {
        if (formErrors.value[error]) return
      }
      store.commit('business/updateAddress', {
        address: address.value.trim(),
        addressLine: addressLine.value.trim(),
        country: country.value,
        region: state.value.trim(),
        city: city.value.trim(),
        zipcode: zipcode.value.trim(),
        isReselling: isReselling.value
      })

      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-biz-address')
      })

      router.push({
        name: 'businessToolsPage'
      })
    }

    const clearState = () => {
      formErrors.value.countryError = false
      state.value = ''
    }

    return {
      address,
      addressLine,
      country,
      state,
      city,
      zipcode,
      nextStep,
      prevStep,
      formErrors,
      updateCountry,
      updateState,
      states,
      countries,
      clearState,
      isReselling,
      updateIsReseeling
    }
  }
})
