<template>
  <div class="form-parent-container">
    <div class="form-child-container">
      <div class="md:text-lg lg:text-xl xl:text-2xl font-medium text-center">
        Type in your business address
      </div>
      <div class="flex flex-col md:pt-5 lg:pt-10 xl:pt-10">
        <label id="listbox-label" class="label">
          Billing Address <span style="color:red;">*</span>
        </label>
        <input
          v-model="address"
          type="text"
          name="address"
          class="input-text"
          placeholder="Address"
          @input="formErrors.addressError = false"
        />
        <error-message
          :isError="formErrors.addressError"
          message="Address is required."
        />
      </div>
      <div class="md:pt-3 lg:pt-6 xl:pt-6">
        <label id="listbox-label" class="label">
          Address line 2
        </label>
        <input
          v-model="addressLine"
          type="text"
          name="addressLine"
          class="input-text"
          placeholder="Address Line"
        />
      </div>
      <div class="md:pt-3 lg:pt-6 xl:pt-6 flex flex-row">
        <div class="flex w-1/2 flex flex-row justify-start item-start">
          <div class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              City <span style="color:red;">*</span>
            </label>
            <input
              v-model="city"
              type="text"
              name="city"
              class="input-text"
              placeholder="City"
              @input="formErrors.cityError = false"
              autocomplete="off"
            />
            <error-message
              :isError="formErrors.cityError"
              message="City is required."
            />
          </div>
        </div>
        <div class="flex w-1/2 flex flex-row justify-end item-end">
          <div v-if="country == 'US'" class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              State / Prov / Region <span style="color:red;">*</span>
            </label>
            <select
              id="state"
              name="state"
              class="state input-text"
              v-model="state"
            >
              <option value="">Select state</option>
              <option
                v-for="({ value, label }, index) in states"
                :key="index"
                :value="value"
                >{{ label }}</option
              >
            </select>
            <error-message
              :isError="formErrors.stateError"
              message="State is required."
            />
          </div>
          <div v-else class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              State / Prov / Region <span style="color:red;">*</span>
            </label>
            <input
              v-model="state"
              type="text"
              name="state"
              class="input-text"
              placeholder="State"
              @input="formErrors.stateError = false"
              autocomplete="off"
            />
            <error-message
              :isError="formErrors.stateError"
              message="State is required."
            />
          </div>
        </div>
      </div>
      <div class="md:pt-6 lg:pt-6 xl:pt-6 flex flex-row">
        <div class="flex w-1/2 flex flex-row justify-start item-start">
          <div class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              Country <span style="color:red;">*</span>
            </label>
            <select
              id="country"
              name="country"
              class="country input-text"
              v-model="country"
              @change="clearState"
            >
              <option value="">Select country</option>
              <option
                v-for="({ value, label }, index) in countries"
                :key="index"
                :value="value"
                >{{ label }}</option
              >
            </select>
            <error-message
              :isError="formErrors.countryError"
              message="Country is required."
            />
          </div>
        </div>
        <div class="flex w-1/2 flex flex-row justify-end item-end">
          <div class="w-11/12 flex flex-col">
            <label id="listbox-label" class="label">
              Postal Code <span style="color:red;">*</span>
            </label>
            <input
              v-model="zipcode"
              type="text"
              name="zipcode"
              class="input-text"
              placeholder="Postal Code"
              @input="formErrors.zipcodeError = false"
              autocomplete="off"
            />
            <error-message
              :isError="formErrors.zipcodeError"
              message="Postal code is required."
            />
          </div>
        </div>
      </div>
      <div class="md:pt-3 lg:pt-6 xl:pt-6 flex flex-row">
        <Tooltip
          :tooltipText="
            'HighLevel has many features to resell to your customers and turn your agency into a SaaS.'
          "
          class="flex flex-row"
        >
          <label id="listbox-label" class="label mr-5 mt-1">
            Do you intend on reselling HighLevel?
            <span style="color:red;">*</span>
          </label>
          <div class="mr-7 mt-1">
            <label class="label">
              <input
                type="radio"
                class="input-radio"
                name="isreselling"
                :value="true"
                v-model="isReselling"
                @change="updateIsReseeling"
              />Yes
            </label>
          </div>
          <div class="mr-7 mt-1">
            <label class="label">
              <input
                type="radio"
                class="input-radio"
                name="isreselling"
                :value="false"
                v-model="isReselling"
                @change="updateIsReseeling"
              />No
            </label>
          </div>
          <error-message
            :isError="formErrors.isResellingError"
            message="Please select any one option"
          />
        </Tooltip>
      </div>
      <div class="md:pt-14 lg:pt-20 xl:pt-20 flex justify-end">
        <button
          class="base-filled-button gray-btn flex justify-center items-center"
          @click="prevStep"
        >
          <span class="mr-1">Back</span>
        </button>
        <button
          class="base-filled-button indigo-btn flex justify-center items-center"
          @click="nextStep"
        >
          <span class="mr-1">Awesome, lets get started</span>
          <Icon icon="arrow-right" height="20" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import ErrorMessage from '@/components/UI/ErrorMessage.vue'
import { Icon, addIcon } from '@iconify/vue'
import arrowRight from '@iconify/icons-mdi/arrow-right'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import countries from '@/util/countries.ts'
import states from '@/util/states.ts'
import Tooltip from './Tooltip.vue'

addIcon('arrow-right', arrowRight)

export default defineComponent({
  components: {
    Icon,
    ErrorMessage,
    Tooltip
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const addressLine = ref(store.state.business.addressLine)
    const address = ref(store.state.business.address)
    const country = ref(store.state.business.country)
    const state = ref(store.state.business.region)
    const city = ref(store.state.business.city)
    const zipcode = ref(store.state.business.zipcode)
    const isReselling = ref(store.state.business.isReselling)

    const formErrors = ref({
      addressError: false,
      countryError: false,
      stateError: false,
      cityError: false,
      zipcodeError: false,
      isResellingError: false
    })

    watch(state, () => {
      formErrors.value.stateError = false
    })

    const verifyErrors = () => {
      if (!country.value) formErrors.value.countryError = true
      if (!state.value || !state.value?.trim())
        formErrors.value.stateError = true
      if (!address.value || !address.value?.trim()) {
        address.value = address.value?.trim()
        formErrors.value.addressError = true
      }
      if (!city.value || !city.value?.trim()) {
        city.value = city.value?.trim()
        formErrors.value.cityError = true
      }
      if (!zipcode.value || !zipcode.value?.trim()) {
        zipcode.value = zipcode.value?.trim()
        formErrors.value.zipcodeError = true
      }
      if (typeof isReselling.value === 'undefined') {
        formErrors.value.isResellingError = true
      }
    }

    const updateCountry = (value: string) => {
      formErrors.value.countryError = false
      country.value = value
      state.value = ''
    }

    const updateState = (value: string) => {
      formErrors.value.stateError = false
      state.value = value
    }

    const prevStep = () => {
      router.push({
        name: 'businessInfoPage'
      })
    }

    const updateIsReseeling = () => {
      formErrors.value.isResellingError = false
    }

    const nextStep = () => {
      verifyErrors()
      for (const error in formErrors.value) {
        if (formErrors.value[error]) return
      }
      store.commit('business/updateAddress', {
        address: address.value.trim(),
        addressLine: addressLine.value.trim(),
        country: country.value,
        region: state.value.trim(),
        city: city.value.trim(),
        zipcode: zipcode.value.trim(),
        isReselling: isReselling.value
      })

      // @ts-ignore
      store?.state?.iframe?.handshake?.then(parent => {
        parent.emit('ga-event', 'onb-biz-address')
      })

      router.push({
        name: 'businessToolsPage'
      })
    }

    const clearState = () => {
      formErrors.value.countryError = false
      state.value = ''
    }

    return {
      address,
      addressLine,
      country,
      state,
      city,
      zipcode,
      nextStep,
      prevStep,
      formErrors,
      updateCountry,
      updateState,
      states,
      countries,
      clearState,
      isReselling,
      updateIsReseeling
    }
  }
})
</script>

<style scoped type="scss"></style>
